<template>
  <div class="person-auth">
      <div class="navigation">
        <div style="display: flex;">
          <div style="display: flex">
            <div class="title" style="color: #2970FF">实名认证</div>
          </div>
        </div>
      </div>
      <div style="margin: 10px 0; overflow: hidden">
        <div>
          <RealName></RealName>
        </div>
      </div>
    </div>
</template>
<script>
import RealName from "@/pages/index/components/auth/RealName.vue";
import Profession from "@/pages/index/components/auth/Profession.vue";
import Expert from "@/pages/index/components/auth/Expert.vue";

export default {
  name: 'PersonAuth',
  components: {Expert, Profession, RealName},
  data() {
    return {
      auth: 1
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {

  },

  methods: {
  }
}
</script>

<style scoped>
.person-auth {
  width: 100%;
}

.person-auth .navigation {
  display: flex;
  justify-content: center;
  position: relative;
  height: 40px;
  padding: 10px 0;
  margin-bottom: 10px;
  background-color: #FFFFFF;
}

.person-auth .title {
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
}

</style>
