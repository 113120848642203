<template>
  <div class="person-auth">
    <div class="navigation">
<!--      <div v-if="auth > 1" class="btn hand op" style="left: 26px" @click="lastAuth">上步认证</div>-->
      <div style="display: flex;">
        <div style="display: flex">
<!--          <div class="number" :style="auth === 1 ? 'backgroundColor: #2970FF' : 'backgroundColor: #B5B5B5'"><span class="number-font">01</span></div>-->
          <div class="title" :style="auth === 1 ? 'color: #2970FF' : 'color: #B5B5B5'">机构认证</div>
        </div>
<!--        <div style="line-height: 50px; margin: 0 40px">-->
<!--          <img v-if="user.authenticationStatus >= 1" src="../../../../assets/imgs/index/next.png">-->
<!--          <img v-else src="../../../../assets/imgs/index/unNext.png">-->
<!--        </div>-->
<!--        <div style="display: flex">-->
<!--          <div class="number" :style="auth === 2 ? 'backgroundColor: #2970FF' : 'backgroundColor: #B5B5B5'"><span class="number-font">02</span></div>-->
<!--          <div class="title" :style="auth === 2 ? 'color: #2970FF' : 'color: #B5B5B5'">专业认证</div>-->
<!--        </div>-->
<!--        <div style="line-height: 50px; margin: 0 40px">-->
<!--          <img v-if="user.authenticationStatus >= 2" src="../../../../assets/imgs/index/next.png">-->
<!--          <img v-else src="../../../../assets/imgs/index/unNext.png">-->
<!--        </div>-->
<!--        <div style="display: flex">-->
<!--          <div class="number" :style="auth === 3 ? 'backgroundColor: #2970FF' : 'backgroundColor: #B5B5B5'"><span class="number-font">03</span></div>-->
<!--          <div class="title" :style="auth === 3 ? 'color: #2970FF' : 'color: #B5B5B5'">专家认证</div>-->
<!--        </div>-->
      </div>
<!--      <div v-if="auth < 3" class="btn hand op" style="right: 26px" @click="nextAuth">下步认证</div>-->
    </div>
    <div style="margin: 10px 0; overflow: hidden">
      <div v-if="auth === 1">
        <OrganRealName></OrganRealName>
      </div>
    </div>
  </div>
</template>
<script>

import OrganRealName from "@/pages/index/components/auth/OrganRealName.vue";

export default {
  name: 'OrganAuth',
  components: {OrganRealName},
  data() {
    return {
      auth: 1
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
  },

  methods: {
    // 上一步认证
    lastAuth() {
      this.auth -= 1
    },

    // 下一步认证
    nextAuth() {
      this.auth += 1
    },
  }
}
</script>

<style scoped>
.person-auth {
  width: 100%;
}

.person-auth .navigation {
  display: flex;
  justify-content: center;
  position: relative;
  height: 40px;
  padding: 10px 0;
  margin-bottom: 10px;
  background-color: #FFFFFF;
}

.person-auth .number {
  width: 40px;
  height: 40px;
  font-weight: 600;
  margin-right: 12px;
  border-radius: 50%;
}

.person-auth .number .number-font {
  color: #FFFFFF;
  font-size: 20px;
  line-height: 38px;
  margin: 0 7px;
}

.person-auth .title {
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
}

.person-auth .btn {
  position: absolute;
  top: 14px;
  width: 100px;
  padding: 6px 0;
  text-align: center;
  color: #FFFFFF;
  font-size: 16px;
  background: #2E73FF;
  border-radius: 5px 5px 5px 5px;
}
</style>
