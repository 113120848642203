<template>
  <div class="real-name" style="overflow: hidden">
    <div class="body-left">
      <div style="padding: 20px 26px 0 26px">
        <div style="font-size: 16px; font-weight: 600; line-height: 22px; margin-bottom: 6px">
          专业认证：
          对用户专业程度进行验证，每一项分
          类全部通过后直接赋予用户专业的标
          记。拥有专业标记的用户可以：
        </div>
        <div style="font-size: 16px; font-weight: 600; color: #2970FF; line-height: 26px">
          1、回答有偿的红包问答<br>
          2、上传知识仓库并进行频道推荐<br>
          3、免费创建个人店铺，并上传个人
          提供的专业技术服务，开展线上的专
          业技术服务<br>
          4、好友推荐与被推荐<br>
        </div>
      </div>
      <el-divider></el-divider>
      <div style="padding: 0 10px">
          <div @click="goMajor('school')" class="hand op" :class="major === 'school' ? 'tags-child-select': 'tags-child'">
            <span style="font-size: 16px; margin-right: 16px">教育经历</span>
            <img v-if="majorData.schoolInfo.length === 0" src="~@/pages/circle/imgs/new1.png"/>
            <img v-else src="~@/pages/circle/imgs/new1.png"/>
          </div>
          <div @click="goMajor('work')" class="hand op" :class="major === 'work' ? 'tags-child-select': 'tags-child'">
            <span style="font-size: 16px; margin-right: 16px">工作经历</span>
            <img v-if="majorData.workInfo.length === 0" src="~@/pages/circle/imgs/old1.png"/>
            <img v-else src="~@/pages/circle/imgs/new1.png"/>
          </div>
          <div @click="goMajor('project')" class="hand op" :class="major === 'project' ? 'tags-child-select': 'tags-child'">
            <span style="font-size: 16px; margin-right: 16px">项目经历</span>
            <img v-if="majorData.projectInfo.length === 0" src="~@/pages/circle/imgs/old1.png"/>
            <img v-else src="~@/pages/circle/imgs/new1.png"/>
          </div>
          <div @click="goMajor('honor')" class="hand op" :class="major === 'honor' ? 'tags-child-select': 'tags-child'">
            <span style="font-size: 16px; margin-right: 16px">获得荣誉</span>
            <img v-if="majorData.honorInfo.length === 0" src="~@/pages/circle/imgs/old1.png"/>
            <img v-else src="~@/pages/circle/imgs/new1.png"/>
          </div>
          <div @click="goMajor('book')" class="hand op" :class="major === 'book' ? 'tags-child-select': 'tags-child'">
            <span style="font-size: 16px; margin-right: 16px">专业证书</span>
            <img v-if="majorData.professionalTitleInfo.length === 0 && majorData.NVQCartInfo.length === 0 && majorData.otherCartInfo.length === 0" src="~@/pages/circle/imgs/old1.png"/>
            <img v-else src="~@/pages/circle/imgs/new1.png"/>
          </div>

        <div style="margin: 50px 0 0 0">
          <div style="display: flex; justify-content: center">
            <div v-if="user.majorAuthStatus == 0 || user.majorAuthStatus == 2" :disabled="user.realNameStatus != 2" class="hand op update-btn" @click="updateMajorStatus">
              <i class="el-icon-document-checked" style="font-size: 18px; margin-right: 2px"></i>
              <span>提交专业审核</span>
            </div>
            <div v-if="user.majorAuthStatus == 1" class="up-btn">
              <i class="el-icon-time" style="font-weight: 600; margin-right: 2px; vertical-align: middle"></i>
              <span style="vertical-align: middle">正在进行审核</span>
            </div>
          </div>
          <div style="margin: 10px 26px 0 26px; color: #999999">
            注： 进行审核时所有内容无法进行编辑。当该审核全部通过没有新添加内容时审核按钮将无法使用，避免重复提交操作。
          </div>
        </div>
      </div>
    </div>

    <div class="body-right">
      <div style="padding: 20px">
        <professional :major="major" @changeMajor="changeMajor"></professional>
      </div>
    </div>
  </div>
</template>
<script>

import Professional from "@/pages/circle/components/Professional.vue";

export default {
  name: 'Profession',
  components: {Professional},
  data() {
    return {
      auth: 1,
      major: 'school',
      majorData: {
        schoolInfo: [],
        workInfo: [],
        projectInfo: [],
        honorInfo: [],
        professionalTitleInfo: [],
        NVQCartInfo: [],
        otherCartInfo: []
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getMajorSave()
  },

  methods: {
    changeMajor(e) {
      this.majorData = e
    },
    // 获取专业保存信息
    getMajorSave() {
      var that = this;
      that.newApi.getUserMajorAuthTemp().then(res => {
        that.majorData = res.data;
      })
    },

    // 选择项
    goMajor(e) {
      this.major = e
    },

    // 提交专业审核
    updateMajorStatus() {
      this.newApi.saveExamineStatus({statusType: 2}).then(res => {
        if (res.isSuccess == 1) {
          this.utils.sus('提交成功，等待审核')
          this.user.majorAuthStatus = res.data.majorAuthStatus
        }
      })
    },
  }
}
</script>

<style scoped>
.real-name {
  width: 100%;
}
.real-name .body-left {
  width: 340px;
  min-height: 890px;
  margin-right: 10px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .body-right {
  width: 1150px;
  min-height: 890px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .update-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #2970FF;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
  border: 1px #2970FF solid
}

.real-name .up-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #FFFFFF;
  background-color: #FF9800;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
}

.real-name .tags-child {
  height: 44px;
  line-height: 44px;
  text-align: center;
}

.real-name .tags-child-select {
  height: 44px;
  line-height: 44px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2970FF;
  border-radius: 5px;
}
</style>
