<template>
  <div>
    <div class="organ-form">
      <el-form :model="organForm" :rules="organRules" ref="organForm" v-if="showForm === true">
        <el-form-item prop="idCardNo" style="margin-bottom: 40px">
          <div class="resume-title">
            法人身份证号
          </div>
          <div style="display: flex;">
            <!--                    <div v-if="name === false" style="min-width: 300px;margin-right: 16px; font-size: 18px; line-height: 30px">-->
            <!--                        {{ organForm.idCardNo }}</div>-->
            <el-input type="textarea"
                      resize="none"
                      :rows="1"
                      v-model="organForm.idCardNo"
                      maxlength="18"
                      :placeholder="user.realNameStatus > 0 ? '******************' : ''"
                      :disabled="user.realNameStatus > 0"
                      :show-word-limit="true"
                      style="margin-right: 16px; line-height: 22px; width: 300px">
            </el-input>
            <!--                    <img v-if="name === false" src="../../../assets/imgs/circle/resumeEdit.png" class="hand op" style="width: 30px; height: 30px" @click="name = true"/>-->
          </div>
        </el-form-item>
        <el-form-item prop="codeImg" style="margin-bottom: 50px" v-if="user.realNameStatus === 0">
          <div class="resume-title">
            营业执照图片（不对外展示）
          </div>
          <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
            <el-upload accept="image/*" class="upload-demo" action="" drag :http-request="($event)=>uploadImg($event, 'codeImg')" :limit='1' :on-remove="$event => removeImg($event, 'codeImg')">
              <img v-if="organForm.url" width="100%" height="338px" :src="organForm.url"/>
              <i class="el-icon-plus"></i>
              <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
              <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
            </el-upload>
          </div>
        </el-form-item>

        <el-form-item prop="codeImg" style="margin-bottom: 50px" v-else>
          <div class="resume-title">
            营业执照信息（不对外展示）
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <div>
              <div style="font-size: 16px">名称</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.Name}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">社会信用代码</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.RegisterNumber}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">类型</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.Type}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">注册日期</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.EstablishDate}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">经营者</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.LegalPerson}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">有效日期</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.ValidPeriod}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">注册资本</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.Capital}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">经营场所</div>
              <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
                {{info.address}}
              </div>
            </div>
            <div>
              <div style="font-size: 16px">经营范围</div>
              <div style="margin-bottom: 16px; width: 840px; margin-right: 40px">
                {{info.Business}}
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div style="margin-bottom: 50px" v-if="showForm === false">
        <div class="resume-title">
          人脸识别
        </div>
        <div style="margin-bottom: 20px">
          <span style="margin-left: 20px; color: #6d737b" v-if="JSON.stringify(faceStatus) == '{}'">*请点击按钮进行人脸识别，完成机构认证</span>
          <span style="margin-left: 20px; color: #cc0000" v-else>{{faceStatus.msg}}</span>
        </div>
        <div v-if="qrShow" style="margin-bottom: 20px">
          <vue-qr
              :text="qr"
              :size="250"
              :logoScale="0.2">
          </vue-qr>
        </div>

        <div style="display: flex; flex-wrap: wrap">
          <div>
            <div style="font-size: 16px">名称</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.Name}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">社会信用代码</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.RegisterNumber}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">类型</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.Type}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">注册日期</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.EstablishDate}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">经营者</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.LegalPerson}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">有效日期</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.ValidPeriod}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">注册资本</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.Capital}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">经营场所</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.address}}
            </div>
          </div>
          <div>
            <div style="font-size: 16px">经营范围</div>
            <div style="margin-bottom: 16px; width: 400px; margin-right: 40px">
              {{info.Business}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LoginModule from "../../../components/common/LoginModule";
export default {
  name: "organFormAuth",
  components: {LoginModule},
  data() {
    return {
      showForm: true,
      qr:'',
      qrShow:false,
      faceStatus: {},
      info: {
        Business: "",
        Capital: "",
        EstablishDate: "",
        LegalPerson: "",
        Name: "",
        RegisterNumber: "",
        Type: "",
        ValidPeriod: "",
        address: ""
      },
      organForm: {
        idCardNo: '',
        url: '',
        qualifications: [],
        certificate: []
      },
      organRules: {
        idCardNo: [
          { required: true, message: '请输入正确的身份证号码', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码', trigger: 'blur'},
          { min: 18, max: 18, message: '身份证号码格式错误', trigger: 'blur'}
        ]

      },

      qualificationShow: false,
      qualification: {
        name:"",
        lv:"",
        range:"",
        industry:"",
        endTime:"",
        image:""
      },
      honorShow: false,
      honor: {
        name: '',
        time: '',
        image: ''
      },

      name: false,
      signature: false,

      companyCodeImage: '',

      // 绑定手机号
      type: '',
      showPhoneWin: false,

    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getOrganInfo(this.user.uuid);
    this.$EventBus.$on('faceData', (data) => {
      this.faceStatus = data;
      if (this.faceStatus.code == '200') {
        this.utils.sus(this.faceStatus.msg);
        // this.newApi.getUserInfo({uuid: this.user.uuid});
        this.qrShow = false
      } else {
        this.utils.sus(this.faceStatus.msg);
        this.qrShow = true
      }
    })
  },

  methods: {

    // 获取机构认证信息
    getOrganInfo() {
      this.newApi.getSpaceInfo().then(res => {
        this.info = res.data.institutionData
      })
    },

    // 保存
    updateInfo() {
      if (!this.organForm.idCardNo) {
        this.utils.err('请输入身份证号码！');
        return
      }
      if (!this.organForm.url) {
        this.utils.err('请上传营业执照！');
        return
      }
      var param = {};
      param.idCardNo  =  this.organForm.idCardNo;
      param.url = this.organForm.url;
      param.MetaInfo = JSON.stringify(window.getMetaInfo());
      param.token = localStorage.getItem("token");
      this.$refs.organForm.validate((valid) => {
        if (valid) {
          this.newApi.agencyCertification(param).then(res =>{
            if (res.isSuccess == 1) {
              this.utils.sus('上传信息成功！');
              this.qr = res.data.resultObject.certifyUrl;
              this.getOrganInfo(this.user.uuid);
              this.showForm = false;
              this.qrShow = true
            }
          })
        }
      })
    },

    // 取消
    cancelInfo() {
      this.getOrganInfo(this.user.uuid);
    },

    // 上传图片
    uploadImg(params, e) {
      var that = this;
      var file = params.file;
      const isLt5M = file.size / 1024 / 1024 < 8;
      if (isLt5M) {
        if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
          that.utils.upload(file,function(url){
            if (!url){
              return false;
            }
            if (e === 'codeImg') {
              that.organForm.url = url
            }
            if (e === 'logo') {
              that.organForm.other.logo = url
            }
          })
        } else {
          this.$message.error('只能上传图片类型!')
        }

      } else {
        this.$message.error('上传文件大小不能超过 8MB!')
      }
    },

    // 移除图片
    removeImg($event, e) {
      if (e === 'codeImg') {
        this.organForm.url = ''
      }
      if (e === 'logo') {
        this.organForm.other.logo = ''
      }
    },
  },
}
</script>

<style scoped>
.resume-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}
.upload-demo {
  vertical-align: middle;
}

.el-icon-plus {
  color: #2970FF;
  font-size: 44px;
  font-weight: 600;
  text-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin: 100px 0 14px 0;
}

/deep/ .el-upload-dragger {
  width: 500px;
  height: 300px;
  background-color: #FBFBFB;
}
</style>
