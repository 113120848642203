<template>
  <div class="real-name" style="overflow: hidden">
    <div class="body-left" v-if="auth === 1">
      <div style="padding: 20px 26px">
        <div style="font-size: 16px; font-weight: 600; line-height: 22px; margin-bottom: 6px">
          实名认证：
          是对用户资料真实性进行的一种验证
          审核，有助于建立完善可靠的互联网
          信用基础。完成实名后可以：
        </div>
        <div style="font-size: 16px; font-weight: 600; color: #2970FF; line-height: 26px">
          1.发布朋友圈动态<br>
          2.查看用户个人空间<br>
          3.关注、添加好友<br>
          4.朋友圈动态贴标签，收藏，评论，
          转发，点赞<br>
          5.创建动态话题<br>
          6.创建活动报名<br>
          7.创建Room<br>
          8.申请成为Room成员<br>
          9.Room中发布内容，评论<br>
          10.活动报名<br>
          11.付费文档下载<br>
          12.创建店铺<br>
          13.所有钱包功能<br>
        </div>
      </div>
    </div>

    <div class="body-right">
      <div style="padding: 30px">
        <Certification :isOrgan="false"></Certification>
      </div>
    </div>
  </div>
</template>
<script>
import Certification from "@/pages/circle/components/Certification.vue";

export default {
  name: 'RealName',
  components: {Certification},
  data() {
    return {
      auth: 1
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
  },

  methods: {

  }
}
</script>

<style scoped>
.real-name {
  width: 100%;
}
.real-name .body-left {
  width: 340px;
  min-height: 890px;
  margin-right: 10px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .body-right {
  width: 1150px;
  min-height: 890px;
  background-color: #FFFFFF;
  float: left;
}
</style>
