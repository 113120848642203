<template>
  <div class="real-name" style="overflow: hidden">
    <div class="body-left">
      <div style="padding: 20px 26px 0 26px">
        <div style="font-size: 16px; font-weight: 600; line-height: 22px; margin-bottom: 6px">
          专家认证：
          需要在专业领域内有所建树；对从事
          的专业有自己独立的见解并形成系统
          理论，并对具体科技问题能够有合理
          诠释的；有独立系统阐述自己观点理
          论的著作或论文的专家。获得认证成
          为专家后可以：
        </div>
        <div style="font-size: 16px; font-weight: 600; color: #2970FF; line-height: 26px">
          1、使用除会员外全部的百工宝功能<br>
          2、开放专家空间及专家服务功能，专家可以提供专业咨询服务。
        </div>
      </div>
      <el-divider></el-divider>

      <div style="padding: 0 10px">
        <div @click="goExpert('introduce')" class="hand op" :class="expert === 'introduce' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">专家简述</span>
          <img v-if="!expertData.introduction" src="~@/pages/circle/imgs/new1.png"/>
          <img v-else src="~@/pages/circle/imgs/new1.png"/>
        </div>
        <div @click="goExpert('synopsis')" class="hand op" :class="expert === 'synopsis' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">工作业绩</span>
          <img v-if="expertData.sketch.length === 0" src="~@/pages/circle/imgs/old1.png"/>
          <img v-else src="~@/pages/circle/imgs/new1.png"/>
        </div>
        <div @click="goExpert('thesis')" class="hand op" :class="expert === 'thesis' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">专著论文</span>
          <img v-if="expertData.paper.monograph.length === 0 && expertData.paper.paper.length === 0" src="~@/pages/circle/imgs/old1.png"/>
          <img v-else src="~@/pages/circle/imgs/new1.png"/>
        </div>
        <div @click="goExpert('socialTitle')" class="hand op" :class="expert === 'socialTitle' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">社会头衔</span>
          <img v-if="expertData.rank.length === 0" src="~@/pages/circle/imgs/old1.png"/>
          <img v-else src="~@/pages/circle/imgs/new1.png"/>
        </div>
        <div @click="goExpert('expertOther')" class="hand op" :class="expert === 'expertOther' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">其他认证</span>
          <img v-if="expertData.other.length === 0" src="~@/pages/circle/imgs/old1.png"/>
          <img v-else src="~@/pages/circle/imgs/new1.png"/>
        </div>
        <div @click="goExpert('topic')" class="hand op" :class="expert === 'topic' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">咨询话题</span>
          <img v-if="topic.length === 0" src="~@/pages/circle/imgs/old1.png"/>
          <img v-else src="~@/pages/circle/imgs/new1.png"/>
        </div>

        <div style="margin: 50px 0 0 0">
          <div style="display: flex; justify-content: center">
            <div v-if="user.expertAuthStatus == 0 || user.expertAuthStatus == 2" :disabled="user.realNameStatus != 2" class="hand op update-btn" @click="updateExpertStatus">
              <i class="el-icon-document-checked" style="font-size: 18px; margin-right: 2px"></i>
              <span>提交专家审核</span>
            </div>
            <div v-if="user.expertAuthStatus == 1" class="up-btn">
              <i class="el-icon-time" style="font-weight: 600; margin-right: 2px; vertical-align: middle"></i>
              <span style="vertical-align: middle">正在进行审核</span>
            </div>
          </div>
          <div style="margin: 10px 26px 0 26px; color: #999999">
            注： 进行审核时所有内容无法进行编辑。当该审核全部通过没有新添加内容时审核按钮将无法使用，避免重复提交操作。
          </div>
        </div>
      </div>
    </div>

    <div class="body-right">
      <div style="padding: 20px">
        <expert :expert="expert" @changeExpert="changeExpert" @changeTopic="changeTopic"></expert>
      </div>
    </div>
  </div>
</template>
<script>
import expert from "@/pages/circle/components/Authentication/Expert.vue";
export default {
  name: 'Expert',
  components: {expert},
  data() {
    return {
      auth: 1,
      expert: 'introduce',
      expertData: {
        introduction: "",
        label: [],
        sketch: [],
        paper: {
          monograph: [],
          paper: []
        },
        rank: [],
        topic: [],
        other: []
      },

      topic: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getExpertSave()
  },

  methods: {
    changeExpert(e) {
      this.expertData = e
    },

    changeTopic(e) {
      this.topic = e
    },

    // 获取专家保存信息
    getExpertSave() {
      var that = this;
      that.newApi.getUserExpertAuthTemp().then(res => {
        that.expertData = res.data;
      })
    },

    // 获取专家话题
    getExpertTopics() {
      this.newApi.getExpertTopic({uuid: this.user.uuid}).then(res =>{
        if (res.isSuccess == 1) {
          this.topic = res.data
        }
      })
    },

    // 选择项
    goExpert(e) {
      this.expert = e
    },

    // 提交专家审核
    updateExpertStatus() {
      this.newApi.saveExamineStatus({statusType: 3}).then(res =>{
        if (res.isSuccess == 1) {
          this.utils.sus('提交成功，等待审核')
          this.user.expertAuthStatus = res.data.expertAuthStatus
        }
      })
    },
  }
}
</script>

<style scoped>
.real-name {
  width: 100%;
}
.real-name .body-left {
  width: 340px;
  min-height: 890px;
  margin-right: 10px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .body-right {
  width: 1150px;
  min-height: 890px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .update-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #2970FF;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
  border: 1px #2970FF solid
}

.real-name .up-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #FFFFFF;
  background-color: #FF9800;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
}

.real-name .tags-child {
  height: 44px;
  line-height: 44px;
  text-align: center;
}

.real-name .tags-child-select {
  height: 44px;
  line-height: 44px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2970FF;
  border-radius: 5px;
}
</style>
