<template>
  <div class="auth">
    <div class="head">
      <div style="width: 1500px; margin: 0 auto">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <img v-if="user.userType === 1" src="~@/assets/imgs/index/auth.png" style="width: 334px; height: 50px; margin-right: 10px">
            <img v-if="user.userType === 2" src="~@/assets/imgs/index/organ.png" style="width: 334px; height: 50px; margin-right: 10px">
            <el-popover
                placement="right-end"
                width="300"
                trigger="hover">
              <div v-if="user.userType === 1" style="color: #2970FF; font-weight: 600">
                用户需按照流程进行认证，完成实名认证后
                才能进行专业认证，待专业认证通过后才能
                进行专家认证。
              </div>
              <div v-if="user.userType === 2" style="color: #2970FF; font-weight: 600">
                企业、机关事业、社团只需要进行机构实名
                和机构认证，个体除了机构实名外还需要通
                过专业认证和专家认证。
              </div>
              <img class="hand op" slot="reference" src="~@/assets/imgs/index/help.png" style="width: 20px; height: 20px; margin-top: 12px">
            </el-popover>
          </div>
          <div>
            <AvatarMenu></AvatarMenu>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 1500px; margin: 0 auto">
      <PersonAuth v-if="user.userType === 1"></PersonAuth>
      <OrganAuth v-if="user.userType === 2"></OrganAuth>
    </div>
  </div>
</template>
<script>
import PersonAuth from "@/pages/index/components/auth/PersonAuth.vue";
import OrganAuth from "@/pages/index/components/auth/OrganAuth.vue";
import AvatarMenu from "@/components/common/AvatarMenu.vue";

export default {
  name: 'Auth',
  components: {AvatarMenu, OrganAuth, PersonAuth},
  data() {
    return {
      auth: 1
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
  },

  methods: {}
}
</script>
<style scoped>
  .auth {
    width: 100%;
    min-height: 100vh;
    background-color: #F2F2F2;
  }

  .auth .head {
    width: 100%;
    height: 50px;
    padding: 25px 0;
    margin-bottom: 10px;
    background-color: #FFFFFF;
  }

  .auth .navigation {
    display: flex;
    justify-content: center;
    height: 40px;
    padding: 10px 0;
    margin-bottom: 10px;
    background-color: #FFFFFF;
  }

  .auth .number {
    width: 40px;
    height: 40px;
    font-weight: 600;
    margin-right: 12px;
    border-radius: 50%;
  }

  .auth .number .number-font {
    color: #FFFFFF;
    font-size: 20px;
    line-height: 38px;
    margin: 0 7px;
  }

  .auth .title {
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
  }
</style>
