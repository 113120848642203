<template>
  <div class="real-name" style="overflow: hidden">
    <div class="body-left">
      <div style="padding: 20px 26px">
        <div style="font-size: 16px; font-weight: 600; line-height: 22px; margin-bottom: 20px">
          该账号的机构类型是：<span style="color: #2970FF;">企业账号</span>
        </div>
        <div style="font-size: 16px; font-weight: 600; line-height: 22px; margin-bottom: 6px">
          机构认证：<br>
          企业、机关事业、社团需要进行机构
          实名和机构资质和荣誉等认证，个体
          除机构实名外则需要通过的是专业认
          证和专家认证。<br>
          完成全部的机构认证后可以：
        </div>
        <div style="font-size: 16px; font-weight: 600; color: #2970FF; line-height: 26px">
          1.发布朋友圈动态<br>
          2.查看用户个人空间<br>
          3.关注、添加好友<br>
          4.朋友圈动态贴标签，收藏，评论，
          转发，点赞<br>
          5.创建动态话题<br>
          6.创建活动报名<br>
          7.创建Room<br>
          8.申请成为Room成员<br>
          9.Room中发布内容，评论<br>
          10.活动报名<br>
          11.付费文档下载<br>
          12.创建店铺<br>
          13.所有钱包功能<br>
          14.机构认证后可创建子账号<br>
        </div>
      </div>
    </div>

    <div class="body-right">
      <div style="padding: 30px">
        <OrganFormAuth></OrganFormAuth>
      </div>
    </div>
  </div>
</template>
<script>
import OrganFormAuth from "@/pages/circle/components/organAuth.vue";

export default {
  name: 'OrganRealName',
  components: {OrganFormAuth},
  data() {
    return {
      auth: 1
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
  },

  methods: {

  }
}
</script>

<style scoped>
.real-name {
  width: 100%;
}
.real-name .body-left {
  width: 340px;
  min-height: 890px;
  margin-right: 10px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .body-right {
  width: 1150px;
  min-height: 890px;
  background-color: #FFFFFF;
  float: left;
}
</style>
